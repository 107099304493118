import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import "@fortawesome/fontawesome-free/css/all.min.css"
import { getMobileOperatingSystem } from "./app"

const IndexPage = ({ data, location }) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [isMobile, setIsMobile] = useState(true)
  const { allMarkdownRemark } = data
  const params = new URLSearchParams(location.search)
  const lang = params.get("lang")
  const node = allMarkdownRemark.nodes.find(item =>
    lang === "ar" ? item.html.includes("شروط") : item.html.includes("Terms")
  )
  let html = ""
  if (node) {
    html = node.html
  }

  useEffect(() => {
    const OS = getMobileOperatingSystem()
    setIsMobile(OS === "Android" || OS === "iOS")
    setIsLoaded(true)
  }, [])

  return isLoaded ? (
    <div
      style={{
        paddingTop: `${isMobile ? "3%" : "10vh"}`,
        //test 1
        paddingBottom: "20vh",
        paddingLeft: `calc(${
          isMobile ? "5%" : "20%"
        } + env(safe-area-inset-left))`,
        paddingRight: `calc(${
          isMobile ? "5%" : "20%"
        } + env(safe-area-inset-right))`,
      }}
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    />
  ) : null
}

export default IndexPage

export const query = graphql`
  query MyQuery {
    allMarkdownRemark {
      nodes {
        html
      }
    }
  }
`
